import React from 'react';
import injectSheet from 'react-jss';
import Modal from 'react-modal';
import Helmet from 'react-helmet';

import Translate from '../../components/Translate';
import Layout from '../../components/layout';
import coachImage from '../../data/images/features/coach/hero-garminCoach.jpg';
import coachImageMobile from '../../data/images/features/coach/hero-garminCoach-mobile.jpg';
import coachGoalImage from '../../data/images/features/coach/hero-your-race-goal.jpg';
import coachGoalImageMobile from '../../data/images/features/coach/hero-your-race-goal-mobile.jpg';
import coachPersonalized from '../../data/images/features/coach/lifestyle_connect_update_FNL.jpg';
import coachPersonalizedMobile from '../../data/images/features/coach/Mobile_connect_Mcjt-19084.jpg';
import coachMobile from '../../data/images/features/coach/phone-PersonalCoach.png';
import jeffgallowayImg from '../../data/images/features/coach/coach-JeffGalloway.png';
import amyparkersonImg from '../../data/images/features/coach/coach-AmyParkersonMitchell.png';
import gregmcmillanImg from '../../data/images/features/coach/coach-GregMcMillan.png';
import InfoSection from '../../components/infoSection';
import { isChinaUser } from '../../config/isChina';
import themeColors from '../../styles/theme';
import Button from '../../components/button';

const styles = {
    section: {
        background: '#000',
        padding: '30px 10% 30px 10%',
        display: 'flex',
        flexDirection: 'column',
        color: '#fff',
        height: '80vh',
        minHeight: '800px',
        '& h2': {
            fontSize: '2.7em',
            textAlign: 'center',
            lineHeight: '40px',
            fontWeight: '500',
            margin: '35px 0 50px 0'
        },
        '& h3': {
            fontSize: '1.9em',
            lineHeight: '30px',
            fontWeight: '500'
        },
        '& p': {
            fontWeight: 400,
            fontSize: '1.2em',
            width: '70%'
        }
    },
    instructions: {
        flexBasis: '100%',
        display: 'flex',
        justifyContent: 'space-evenly',
        '& p': {
            width: '80%',
            paddingLeft: '10%'
        }
    },
    column: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        textAlign: 'center'
    },
    buttonLight: {
        textDecoration: 'none',
        color: 'inherit',
        display: 'block',
        width: '80%',
        margin: '30px 0 0 10%',
        textAlign: 'center',
        '& span': {
            width: '100px',
            border: '1px solid #fff',
            padding: '10px 15px 10px 15px'
        }
    },
    modal: {
        width: '800px',
        margin: '7% 0 0 15%',
        background: '#fff',
        '& iframe': {
            margin: '0 0 10px 20px'
        }
    },
    modalHeader: {
        padding: '7px 15px',
        color: '#222',
        overflow: 'hidden',
        lineHeight: '1',
        display: 'flex'
    },
    modalCloseButton: {
        color: '#888',
        cursor: 'pointer',
        border: '0',
        opacity: '1',
        padding: '0',
        fontSize: '15px',
        background: 'transparent',
        fontWeight: '400',
        lineHeight: '11px',
        margin: '7px 0 0 auto'
    },
    '@media only screen and (max-width: 767px)': {
        instructions: {
            flexDirection: 'column'
        },
        column: {
            marginBottom: '65px'
        },
        section: {
            height: '100%',
            '& h2': {
                fontSize: '2em'
            },
            '& h3': {
                fontSize: '1.7em'
            },
            '& p': {
                fontSize: '1em'
            }
        }
    }
};

Modal.setAppElement(`#___gatsby`);

class CoachPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            modalIsOpen: false,
            isMobile: false,
            isChinaUser: false
        };
        this.openModal = this.openModal.bind(this);
        this.closeModal = this.closeModal.bind(this);
    }

    componentDidMount() {
        this.resize();
        window.addEventListener('resize', this.resize.bind(this));

        this.setState({ isChinaUser: isChinaUser() });
    }

    resize() {
        if (typeof window !== `undefined`) {
            let renderMobile = window.innerWidth <= 767;
            if (renderMobile !== this.state.isMobile) {
                this.setState({ isMobile: renderMobile });
            }
        }
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.resize);
    }

    openModal() {
        this.setState({ modalIsOpen: true });
    }

    closeModal() {
        this.setState({ modalIsOpen: false });
    }

    render() {
        const { classes } = this.props;
        const button = this.state.isMobile ? (
            <Button size="large">
                <a href="https://youtu.be/LmZpXPpw2F0">{<Translate ns="prelogin_pages" content="watch_the_video" />}</a>
            </Button>
        ) : (
            <Button size="large" onClick={this.openModal}>
                {<Translate ns="prelogin_pages" content="watch_the_video" />}
            </Button>
        );
        const yourPersonalizedPlan20Link = this.state.isChinaUser
            ? 'https://support.garmin.com/zh-CN/?faq=IkvWNeIoSd48GIYCjkhlo7'
            : 'https://support.garmin.com/sas/connect/?productId=73207&contentId=IkvWNeIoSd48GIYCjkhlo7';

        return (
            <Layout>
                <div className={classes.landingPage}>
                    <Helmet>
                        <title>Garmin Coach</title>
                        <meta name="title" content="Garmin Coach" />
                        <meta property="og:title" content="Garmin Coach" />
                        <meta property="og:url" content={`https://connect.garmin.com/features/coach`} />
                        <meta
                            property="og:image"
                            content="http://static.garmincdn.com/com.garmin.connect/content/images/garmin-connect-fb3.gif"
                        />
                        <meta name="twitter:title" content="Garmin Coach" />
                    </Helmet>
                    <InfoSection
                        contentTitle={<Translate ns="prelogin_pages" content="garmin_coach" />}
                        contentList={[<Translate ns="prelogin_pages" content="garmin_coach_2_0_content" />]}
                        desktopImage={coachImage}
                        mobileImage={coachImageMobile}
                        allowFullScreenImage={true}
                        allowFullScreenImageMobile={true}
                        textAlign="right"
                        textColor={themeColors.white}
                    >
                        <div>
                            {!this.state.isChinaUser && button}
                            <Modal
                                isOpen={this.state.modalIsOpen}
                                onRequestClose={this.closeModal}
                                contentLabel="Garmin Coach"
                                className={classes.modal}
                            >
                                <div className={classes.modalHeader}>
                                    <button className={classes.modalCloseButton} onClick={this.closeModal}>
                                        x
                                    </button>
                                </div>
                                <iframe
                                    title="Youtube id LmZpXPpw2F0"
                                    src="https://www.youtube.com/embed/LmZpXPpw2F0?rel=0&autohide=1&showinfo=0&modestbranding=1&theme=light"
                                    frameBorder="0"
                                    allowFullScreen
                                    width="760"
                                    height="428"
                                />
                            </Modal>
                        </div>
                    </InfoSection>

                    <InfoSection
                        contentTitle={<Translate ns="prelogin_pages" content="your_personal_coach" />}
                        contentList={[<Translate ns="prelogin_pages" content="your_personal_coach_2_0_content" />]}
                        desktopImage={coachMobile}
                        mobileImage={coachMobile}
                        minimizeImage={true}
                    />

                    <InfoSection
                        contentTitle={<Translate ns="prelogin_pages" content="your_race_goal" />}
                        contentList={[<Translate ns="prelogin_pages" content="your_race_goal_2_0_content" />]}
                        desktopImage={coachGoalImage}
                        mobileImage={coachGoalImageMobile}
                        allowFullScreenImage={true}
                        textAlign="right"
                        textColor="#ffffff"
                    />

                    <InfoSection
                        contentTitle={<Translate ns="prelogin_pages" content="your_personalized_plan" />}
                        contentList={[<Translate ns="prelogin_pages" content="your_personalized_plan_2_0_content" />]}
                        desktopImage={coachPersonalized}
                        mobileImage={coachPersonalizedMobile}
                        allowFullScreenImage={true}
                        textColor="#ffffff"
                    >
                        <br />
                        <Button>
                            <a href={yourPersonalizedPlan20Link}>
                                {<Translate ns="prelogin_pages" content="your_personalized_plan_2_0_button" />}
                            </a>
                        </Button>
                    </InfoSection>

                    <div className={classes.section}>
                        <h2>Meet the Coaches</h2>
                        <div className={classes.instructions}>
                            <div className={classes.column}>
                                <span>
                                    <img src={jeffgallowayImg} alt="Jeff Galloway" />
                                </span>
                                <div>
                                    <h3>Jeff Galloway</h3>
                                    <p>
                                        <Translate ns="prelogin_pages" content="galloway_intro" />
                                    </p>
                                    <a className={classes.buttonLight} href="http://www.jeffgalloway.com/">
                                        <span>
                                            <Translate ns="prelogin_pages" content="learn_more_label" />
                                        </span>
                                    </a>
                                </div>
                            </div>
                            <div className={classes.column}>
                                <div>
                                    <img src={amyparkersonImg} alt="Amy Parkerson-Mitchell" />
                                </div>
                                <div>
                                    <h3>Amy Parkerson-Mitchell</h3>
                                    <p>
                                        <Translate ns="prelogin_pages" content="parkerson_intro" />
                                    </p>
                                    <a className={classes.buttonLight} href="https://www.roadrunnerskc.com/">
                                        <span>
                                            <Translate ns="prelogin_pages" content="learn_more_label" />
                                        </span>
                                    </a>
                                </div>
                            </div>
                            <div className={classes.column}>
                                <div>
                                    <img src={gregmcmillanImg} alt="Greg McMillan" />
                                </div>
                                <div>
                                    <h3>Greg McMillan</h3>
                                    <p>
                                        <Translate ns="prelogin_pages" content="mcMillan_intro" />
                                    </p>
                                    <a className={classes.buttonLight} href="https://www.mcmillanrunning.com/">
                                        <span>
                                            <Translate ns="prelogin_pages" content="learn_more_label" />
                                        </span>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Layout>
        );
    }
}

export default injectSheet(styles)(CoachPage);
